<script setup lang="ts">
  import {
    Menu as AppMenu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/vue'
  import { useAuthStore } from '@/stores/auth'

  const auth = useAuthStore()
</script>

<template>
  <div class="text-right">
    <app-menu as="div" class="relative inline-block text-left">
      <div>
        <menu-button>
          <span class="i-far-user"></span>
          {{ auth.user?.name }}
        </menu-button>
      </div>

      <transition name="scale">
        <menu-items class="card">
          <menu-item v-slot="{ close }">
            <router-link
              class="menu-item"
              :to="{ name: 'change password' }"
              @click="close"
            >
              <div class="mr-4">
                <span class="i-far-user-lock"></span>
              </div>
              Ganti Password
            </router-link>
          </menu-item>
          <menu-item>
            <button class="menu-item" @click="auth.logout()">
              <div class="mr-4">
                <span class="i-far-arrow-right-from-bracket"></span>
              </div>
              Logout
            </button>
          </menu-item>
        </menu-items>
      </transition>
    </app-menu>
  </div>
</template>

<style lang="postcss" scoped>
  .card {
    @apply absolute right-0 mt-2 p-1 focus:outline-none;
    @apply text-sm;
  }

  .menu-item {
    @apply flex w-full items-center whitespace-nowrap rounded-md px-4 py-2 text-gray-900;
    @apply hover:bg-gradient-to-bl hover:from-primary-500 hover:to-primary-600 hover:text-white;
  }
</style>
