<script setup lang="ts">
  import {
    Dialog as AppDialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { ref } from 'vue'

  defineProps<{
    title?: string
    maxWidth?: string
  }>()

  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <transition-root appear :show="isOpen" as="template">
      <app-dialog as="div" class="relative z-30" @close="close">
        <transition-child
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25"></div>
        </transition-child>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <transition-child
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <dialog-panel
                class="w-full transform space-y-6 overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                :style="{ 'max-width': maxWidth ?? '28rem' }"
              >
                <dialog-title
                  v-if="title"
                  class="max-w-64 text-lg font-medium leading-6 text-gray-900 lg:max-w-80"
                >
                  {{ title }}
                </dialog-title>

                <div class="absolute -top-2 right-4">
                  <button
                    class="flex h-8 w-8 items-center justify-center rounded-full text-gray-500 hover:bg-gray-200"
                    @click="close"
                  >
                    <span class="i-fas-xmark !w-4"></span>
                  </button>
                </div>

                <div>
                  <slot></slot>
                </div>
              </dialog-panel>
            </transition-child>
          </div>
        </div>
      </app-dialog>
    </transition-root>
  </teleport>
</template>

<style lang="postcss" scoped>
  :deep() {
    .modal-footer {
      @apply mt-6 flex items-center justify-center space-x-4;
    }
  }
</style>
