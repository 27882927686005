export default [
  {
    path: '',
    name: 'digital shop order list',
    meta: { title: 'Pesanan Produk Digital' },
    component: () => import('@/views/digital_shop_order/page_list.vue'),
  },
  {
    path: ':id',
    name: 'digital shop order detail',
    props: true,
    meta: { title: 'Pesanan Produk Digital' },
    component: () => import('@/views/digital_shop_order/page_show.vue'),
  },
]
