<script setup lang="ts">
  import { computed } from 'vue'

  const version = __APP_VERSION__
  const currentYear = new Date().getFullYear()
  const developmentYear = 2020

  const footerYear = computed(() => {
    if (currentYear > developmentYear) {
      return developmentYear + '-' + currentYear
    }

    return currentYear
  })
  const errorReportLink = computed(() => {
    return 'https://gradin.co.id/bug-reports?url-web=' + location.origin
  })
</script>

<template>
  <footer class="mt-4 items-end justify-between py-4 text-sm xl:flex">
    <div>
      v{{ version }} -
      <router-link :to="{ name: 'changelogs' }" class="text-link">
        Riwayat Perubahan
      </router-link>
      <br />
      &copy; {{ footerYear }}
      PT. Toko Sejati Sugiarto. All rights reserved.
    </div>
    <div class="pt-4">
      Jika ada kendala atau saran, hubungi kami
      <a target="_blank" :href="errorReportLink" class="text-link ml-1">
        GRADIN Tech Solution
      </a>
    </div>
  </footer>
</template>
