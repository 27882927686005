import { defineStore } from 'pinia'

interface State {
  orders_count: {
    cancel_request: number
    complained: number
    new: number
    waiting_payment: number
  }
  digital_shop_order_count: {
    pending: number
  }
  topups_count: {
    admin_pending: number
  }
  reviews_count: {
    need_moderation: number
  }
  partner_stock_request_count: {
    new: number
  }
  partner_balance_withdraw_count: {
    pending: number
  }
}

export const useDashboardStore = defineStore('Dashboard', {
  state: (): State => ({
    orders_count: {
      cancel_request: 0,
      complained: 0,
      new: 0,
      waiting_payment: 0,
    },
    reviews_count: {
      need_moderation: 0,
    },
    digital_shop_order_count: {
      pending: 0,
    },
    topups_count: {
      admin_pending: 0,
    },
    partner_stock_request_count: {
      new: 0,
    },
    partner_balance_withdraw_count: {
      pending: 0,
    },
  }),
  getters: {
    //
  },
  actions: {
    setTopupsCountPending(total: number) {
      this.topups_count.admin_pending = total
    },
    setDigitalShopOrdersPending(total: number) {
      this.digital_shop_order_count.pending = total
    },
    setOrdersCountNew(total: number) {
      this.orders_count.new = total
    },
    setOrdersCountWaitingPayment(total: number) {
      this.orders_count.waiting_payment = total
    },
    setOrdersCountComplained(total: number) {
      this.orders_count.complained = total
    },
    setOrdersCountCancelRequest(total: number) {
      this.orders_count.cancel_request = total
    },
    setReviewsCount(total: number) {
      this.reviews_count.need_moderation = total
    },
    setPartnerStockRequestCount(total: number) {
      this.partner_stock_request_count.new = total
    },
    setPartnerBalanceWithdrawCount(total: number) {
      this.partner_balance_withdraw_count.pending = total
    },
  },
})
