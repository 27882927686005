import Copy from 'clipboard-copy'
import { addHours } from 'date-fns'
import fnDebounce from 'debounce'
import { nanoid } from 'nanoid'
import {
  SHOP_ORDER_STATUS_CANCELED,
  SHOP_ORDER_STATUS_CANCEL_REQUESTED,
  SHOP_ORDER_STATUS_COMPLAINED,
  SHOP_ORDER_STATUS_COMPLAINT_RESPONDED,
  SHOP_ORDER_STATUS_COMPLETED,
  SHOP_ORDER_STATUS_DELIVERING,
  SHOP_ORDER_STATUS_NEW,
  SHOP_ORDER_STATUS_PROCESSING,
  SHOP_ORDER_STATUS_RECEIVED,
} from '@/models/shop_order'
import { useToastStore } from '@/stores/toast'
import DataUriToBlob from './data_uri_to_blob'
import DateFormatter from './formatters/date'
import NumberFormatter from './formatters/number'

export const debounce = fnDebounce

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
}

export const copy = async (text: string) => {
  const toast = useToastStore()
  try {
    await Copy(text)
    toast.add('Teks telah disalin')
  } catch (error) {
    toast.add('Gagal menyalin teks')
  }
}

export const randomId = nanoid

export const dataUriToBlob = DataUriToBlob

// Filter array by javascript (example usage: search in region page list)
const sanitize = (str: string) => str.toLowerCase().replace(/\s+/g, '')
export const jsSearch = <T>(
  needle: string,
  haystack: T[],
  callback: (item: T) => string,
) => {
  const sanitizedNeedle = sanitize(needle)
  return haystack.filter((item: T) => {
    const sanitizedName = sanitize(callback(item))
    return sanitizedName.includes(sanitizedNeedle)
  })
}

/**
 * TODO use UTC, remove this lines
 * Temporarily add 7 hours to date send to server
 */
export const addServerTimezone = (date: Date): Date => {
  const SERVER_HOUR_OFFSET = 7
  return addHours(date, SERVER_HOUR_OFFSET)
}

/**
 * Translate shop order status to english.
 */
export const translateENShopOrderStatus = (data?: string) => {
  switch (data) {
    case 'diproses':
      return SHOP_ORDER_STATUS_PROCESSING
    case 'dikirim':
      return SHOP_ORDER_STATUS_DELIVERING
    case 'diterima':
      return SHOP_ORDER_STATUS_RECEIVED
    case 'selesai':
      return SHOP_ORDER_STATUS_COMPLETED
    case 'minta-batal':
      return SHOP_ORDER_STATUS_CANCEL_REQUESTED
    case 'dibatalkan':
      return SHOP_ORDER_STATUS_CANCELED
    case 'komplain':
      return SHOP_ORDER_STATUS_COMPLAINED
    case 'komplain-direspon':
      return SHOP_ORDER_STATUS_COMPLAINT_RESPONDED
    default:
      return SHOP_ORDER_STATUS_NEW
  }
}
