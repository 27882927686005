<script setup lang="ts"></script>

<template>
  <tr>
    <td class="w-px whitespace-nowrap">
      <slot name="label"></slot>
    </td>
    <td>:</td>
    <td>
      <slot></slot>
    </td>
  </tr>
</template>

<style lang="postcss" scoped>
  td {
    @apply !border-0 !py-1 align-top first:px-0 first:font-bold last:px-0;
  }
  td:nth-of-type(2) {
    @apply w-px !px-2;
  }
</style>
