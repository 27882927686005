export default {
  path: '/',
  component: () => import('@/views/layouts/layout_auth.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/page_login.vue'),
      meta: { title: 'Login' },
    },
    {
      path: '/password/request',
      name: 'request password',
      component: () => import('@/views/auth/page_request_password.vue'),
      meta: { title: 'Lupa Password' },
    },
    {
      path: '/password/reset/:token',
      name: 'set password',
      props: true,
      component: () => import('@/views/auth/page_set_password.vue'),
      meta: { title: 'Reset Password' },
    },
  ],
}
