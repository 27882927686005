<script setup lang="ts">
  import { useErrorPage } from '@/functions/error_page'
  import { useAuthStore } from '@/stores/auth'

  const auth = useAuthStore()
  const { back, getImage } = useErrorPage()
</script>

<template>
  <div>
    <div class="px-16 pb-16 pt-32">
      <img
        :src="getImage('403.png')"
        alt="403"
        class="mx-auto w-full max-w-sm"
      />
    </div>
    <div class="px-4 text-center">
      <div class="mb-6 text-5xl font-bold tracking-wider text-primary-500">
        DILARANG MASUK !
      </div>
      <h1 class="mb-8 text-lg text-gray-500">
        Anda tidak memiliki hak akses untuk membuka halaman ini.
        <br />
        Laporkan ke admin untuk membuka akses.
      </h1>
      <div class="text-center">
        <div class="mb-2">
          <app-button class="btn-primary w-28" @click="back()">
            Kembali
          </app-button>
        </div>
        <div>
          <app-button class="btn-info w-28" @click="auth.logout()">
            Ganti User
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>
