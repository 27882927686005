<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { debounce } from '@/functions'

  defineProps<{
    placeholder?: string
  }>()

  const route = useRoute()
  const router = useRouter()

  const text = ref<string>((route.query.search ?? '') as string)

  const submitSearch = () => {
    router.push({
      query: {
        // maintain other query params, but reset pagination
        ...route.query,
        page: undefined,
        // append or remove search to query params
        search: text.value ? text.value : undefined,
      },
    })
  }

  const debounced = debounce(submitSearch, 500)

  watch(text, () => {
    debounced()
  })
</script>

<template>
  <app-search
    v-model="text"
    :placeholder="placeholder"
    @submit="debounced.flush()"
  ></app-search>
</template>
