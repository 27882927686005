import { createPinia } from 'pinia'
import 'virtual:uno.css'
import { createApp, markRaw } from 'vue'
import type { Router } from 'vue-router'
import App from './App.vue'
import './assets/styles/tailwind.css'
import globalComponents from './components'
import globalDirectives from './directives'
import router from './routes'
import sentry from './services/sentry'

const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app
  .use(pinia)
  .use(router)
  .use(sentry, router)
  .use(globalComponents)
  .use(globalDirectives)
  .mount('#app')

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}
