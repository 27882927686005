import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useApi } from '@/functions/api'
import { useNotificationStore } from '@/stores/notification'

export const replaceUrlNotification = (url: string) => {
  url = url.replace(location.origin, '')

  const data = {
    order: 'pesanan',
  }

  Object.entries(data).forEach(([key, value]) => {
    url = url.replace(key, value)
  })

  return url
}

export function useNotificationList() {
  const route = useRoute()
  const api = useApi()
  const notificationStore = useNotificationStore()

  const loading = ref(false)
  const notifications = ref<Notification[]>([])
  const pagination = ref<Pagination>()
  const markingAllAsRead = ref(false)

  const getData = async () => {
    loading.value = true
    const params = {
      page: route.query.page,
      type: 'dashboard',
    }
    try {
      const response = await api.GET<ApiCollection<Notification>>(
        'notification',
        params,
      )
      notifications.value = response.data
      pagination.value = response.meta
    } finally {
      loading.value = false
    }
  }

  const markAsRead = async (notification: Notification) => {
    if (notification.read_at) {
      return
    }
    await api.POST(`notification/read/${notification.id}`)
    notificationStore.readOne(notification.id)
  }

  const markAllAsRead = async () => {
    try {
      markingAllAsRead.value = true
      await api.POST('notification/read-all')
      notifications.value.forEach((notification) => {
        notification.read_at = new Date().toJSON()
      })
      notificationStore.readAll()
    } finally {
      markingAllAsRead.value = false
    }
  }

  watch(
    () => route.query.page,
    () => {
      if (route.name === 'notification list') {
        getData()
      }
    },
  )

  return {
    loading,
    pagination,
    notifications,
    getData,
    markAsRead,
    markAllAsRead,
    markingAllAsRead,
  }
}
