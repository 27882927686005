export default [
  {
    path: '',
    name: 'partner list',
    component: () => import('@/views/partner/page_list.vue'),
    meta: { title: 'Daftar Mitra' },
  },
  {
    path: ':id',
    component: () => import('@/views/partner/page_show.vue'),
    props: true,
    children: [
      {
        path: 'pemilik',
        name: 'partner manager',
        props: true,
        component: () => import('@/views/partner/user/page_list.vue'),
        meta: { title: 'Pemilik Mitra' },
      },
      {
        path: 'pelanggan',
        name: 'partner customer',
        props: true,
        component: () => import('@/views/partner/user/page_list.vue'),
        meta: { title: 'Pelanggan Mitra' },
      },
      {
        path: 'stok',
        name: 'partner product',
        props: true,
        component: () => import('@/views/partner/product/page_list.vue'),
        meta: { title: 'Produk Mitra' },
      },
    ],
  },
]
