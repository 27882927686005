export default [
  {
    path: '',
    name: 'employee list',
    meta: { title: 'Daftar Karyawan' },
    props: true,
    component: () => import('@/views/company/employee/page_list.vue'),
  },
  {
    path: ':id',
    name: 'employee show',
    props: true,
    meta: { title: 'Karyawan' },
    component: () => import('@/views/company/employee/page_show.vue'),
  },
]
