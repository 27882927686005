<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    modelValue: boolean
    loading?: boolean
    disabled?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'click', value: boolean): void
  }>()

  const checked = computed({
    set() {
      emit('click', !props.modelValue)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div class="outer" :class="{ changing: loading, active: checked }">
    <label class="switch">
      <input
        v-model="checked"
        type="checkbox"
        :disabled="disabled"
        class="peer opacity-0"
      />
      <span class="absolute inset-0 peer-focus-visible:ring"></span>
      <span class="slider round"></span>
      <span class="sr-only">switch</span>
    </label>
  </div>
</template>

<style lang="postcss" scoped>
  /* Styles from https://www.youtube.com/watch?v=0ZXkFKlS47I */
  .switch {
    --slider-size: theme('spacing.6');
    --switch-width: theme('spacing.10');
    --switch-border: 3px;
  }
  .outer {
    @apply relative;
  }
  .outer.changing .switch {
    pointer-events: none;
  }
  .outer.changing .switch .slider {
    width: var(--slider-size);
    background-color: #9b999b;
  }
  .outer.changing .switch .slider::before {
    height: var(--slider-size);
    width: var(--slider-size);
    @apply inset-0;
    border-style: solid;
    border-width: var(--switch-border);
    @apply border-gray-400;
    @apply border-t-primary-400;
  }
  .outer.active .switch .slider::before {
    right: var(--switch-border);
    left: unset;
  }
  .outer.active.changing .switch .slider::before {
    right: 0px;
    left: unset;
  }

  .switch {
    position: relative;
    display: flex;
    justify-content: center;
    height: var(--slider-size);
    width: var(--switch-width);
  }
  .switch .slider {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    @apply bg-gray-400;
  }
  .switch .slider::before {
    content: '';
    position: absolute;
    height: calc(var(--slider-size) - (2 * var(--switch-border)));
    width: calc(var(--slider-size) - (2 * var(--switch-border)));
    left: var(--switch-border);
    bottom: var(--switch-border);
    background-color: white;
    transition: 0.3s ease;
    transition-property: background-color;
    @apply animate-[spin_1s_ease_infinite];
  }
  .outer.active .switch .slider.round {
    @apply bg-primary-500;
  }
  .switch .slider.round {
    border-radius: calc(var(--slider-size) - (2 * var(--switch-border)));
  }
  .switch .slider.round::before {
    border-radius: 100%;
  }

  input:disabled ~ .slider {
    cursor: not-allowed;
  }
</style>
