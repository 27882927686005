<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useAuthStore } from '@/stores/auth'
  import { useDashboardStore } from '@/stores/dashboard'
  import { useNotificationStore } from '@/stores/notification'
  import SidebarItem from './sidebar_item.vue'

  const auth = useAuthStore()
  const dashboard = useDashboardStore()
  const notification = useNotificationStore()
  const hasPermission = auth.hasPermission

  const showSettingsMenu = ref(false)

  // get active sidebar link, and call scrollIntoView so that the link is visible
  onMounted(() => {
    const el = document.querySelector('.sidebar-content .router-link-active')
    if (el) {
      el.scrollIntoView()
    }
  })
</script>

<template>
  <div class="sidebar-content scrollbar-sidebar">
    <template v-if="!showSettingsMenu">
      <sidebar-item :to="{ name: 'dashboard' }" icon="i-far-chart-line">
        Dashboard
      </sidebar-item>
      <sidebar-item
        :to="{ name: 'notification list' }"
        :count="notification.count"
        class="lg:hidden"
        count-title="Belum dibaca"
        icon="i-far-bell"
      >
        Notifikasi
      </sidebar-item>

      <template
        v-if="
          hasPermission('manage orders') ||
          hasPermission('confirm order payments') ||
          hasPermission('manage mscash topups') ||
          hasPermission('manage digital orders')
        "
      >
        <div class="sidebar-group-label">Transaksi</div>
        <sidebar-item
          v-if="hasPermission('manage orders')"
          :to="{ name: 'shop order list' }"
          icon="i-far-cash-register"
          :count="dashboard.orders_count.new"
          count-title="Pesanan baru"
        >
          Pesanan
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage digital orders')"
          :to="{ name: 'digital shop order list' }"
          icon="i-far-tachograph-digital"
          :count="dashboard.digital_shop_order_count.pending"
          count-title="Pesanan baru"
        >
          Pesanan Produk Digital
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('confirm order payments')"
          :to="{ name: 'shop order payment confirmation' }"
          icon="i-far-money-check-dollar-pen"
          :count="dashboard.orders_count.waiting_payment"
          count-title="Pembayaran perlu konfirmasi admin"
        >
          Konfirmasi Pembayaran
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage mscash topups')"
          :to="{ name: 'topup list' }"
          icon="i-far-wallet"
          :count="dashboard.topups_count.admin_pending"
          count-title="Pembayaran perlu konfirmasi admin"
        >
          Topup MS Cash
        </sidebar-item>
      </template>

      <template
        v-if="
          hasPermission('manage products') ||
          hasPermission('manage stock products') ||
          hasPermission('manage reviews')
        "
      >
        <div class="sidebar-group-label">Produk</div>
        <sidebar-item :to="{ name: 'product list' }" icon="i-far-boxes-stacked">
          Daftar Produk
        </sidebar-item>
        <sidebar-item :to="{ name: 'product category list' }" icon="i-far-tags">
          Kategori Produk
        </sidebar-item>
        <sidebar-item
          :to="{ name: 'product bundle list' }"
          icon="i-far-box-open-full"
        >
          Paket Bundle
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage reviews')"
          :to="{ name: 'product review list' }"
          icon="i-far-star-half-stroke"
          :count="dashboard.reviews_count.need_moderation"
          count-title="Ulasan perlu moderasi admin"
        >
          Ulasan
        </sidebar-item>
      </template>

      <template v-if="hasPermission('manage digital products')">
        <div class="sidebar-group-label">Produk Digital</div>
        <sidebar-item
          :to="{ name: 'digital product list' }"
          icon="i-far-mobile-signal-out"
        >
          Daftar Produk Digital
        </sidebar-item>
      </template>

      <template v-if="hasPermission('manage companies')">
        <div class="sidebar-group-label">Perusahaan</div>
        <sidebar-item :to="{ name: 'company list' }" icon="i-far-building">
          Daftar Perusahaan
        </sidebar-item>
        <sidebar-item :to="{ name: 'employee list' }" icon="i-far-user-group">
          Daftar Karyawan
        </sidebar-item>
      </template>

      <template
        v-if="
          hasPermission('manage promos') ||
          hasPermission('manage vouchers') ||
          hasPermission('manage shop banners') ||
          hasPermission('manage home popups') ||
          hasPermission('manage payment methods') ||
          hasPermission('manage regions') ||
          hasPermission('manage settings mobile app')
        "
      >
        <div class="sidebar-group-label">Toko</div>
        <sidebar-item
          v-if="hasPermission('manage promos')"
          :to="{ name: 'promo list' }"
          icon="i-far-badge-percent"
        >
          Promo
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage vouchers')"
          :to="{ name: 'voucher list' }"
          icon="i-far-ticket"
        >
          Voucher
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage shop banners')"
          :to="{ name: 'banner list' }"
          icon="i-far-gallery-thumbnails"
        >
          Banner
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage home popups')"
          :to="{ name: 'popup home list' }"
          icon="i-far-window-restore"
        >
          Popup Home
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage payment methods')"
          :to="{ name: 'payment method list' }"
          icon="i-far-credit-card"
        >
          Metode Pembayaran
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage regions')"
          :to="{ name: 'region list' }"
          icon="i-far-map-location-dot"
        >
          Wilayah
        </sidebar-item>
        <sidebar-item icon="i-far-gear" @click="showSettingsMenu = true">
          Pengaturan Lainnya
        </sidebar-item>
      </template>

      <template
        v-if="
          hasPermission('manage partners') ||
          hasPermission('manage partner stock requests') ||
          hasPermission('manage partner balance withdraws')
        "
      >
        <div class="sidebar-group-label">Mitra</div>
        <sidebar-item
          v-if="hasPermission('manage partner products')"
          :to="{ name: 'partner product list' }"
          icon="i-far-cubes"
        >
          Produk Mitra
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage partners')"
          :to="{ name: 'partner list' }"
          icon="i-far-handshake"
        >
          Daftar Mitra
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage partner stock requests')"
          :to="{ name: 'partner stock request list' }"
          icon="i-far-hand-holding-box"
          :count="dashboard.partner_stock_request_count.new"
          count-title="Belum Diproses"
        >
          Permintaan Stock
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('see partner shop orders')"
          :to="{ name: 'partner shop order list' }"
          icon="i-far-money-check-dollar"
        >
          Penjualan Mitra
        </sidebar-item>
        <sidebar-item
          v-if="hasPermission('manage partner balance withdraws')"
          :to="{ name: 'partner withdraw all' }"
          icon="i-far-clipboard-list-check"
          :count="dashboard.partner_balance_withdraw_count.pending"
          count-title="Belum Diproses"
        >
          Penarikan Saldo Profit
        </sidebar-item>
      </template>

      <div class="sidebar-group-label">Dashboard</div>
      <sidebar-item
        v-if="hasPermission('manage admins')"
        :to="{ name: 'admin list' }"
        icon="i-far-user"
      >
        Daftar Admin
      </sidebar-item>
      <sidebar-item
        v-if="hasPermission('manage admins')"
        :to="{ name: 'role list' }"
        icon="i-far-shield-check"
      >
        Hak Akses
      </sidebar-item>
      <sidebar-item :to="{ name: 'change password' }" icon="i-far-user-lock">
        Ganti Password
      </sidebar-item>
      <sidebar-item
        icon="i-far-arrow-right-from-bracket"
        @click="auth.logout()"
      >
        Logout
      </sidebar-item>
    </template>
    <template v-else>
      <sidebar-item icon="i-far-arrow-left" @click="showSettingsMenu = false">
        Kembali
      </sidebar-item>

      <div class="sidebar-group-label">Pengaturan</div>
      <sidebar-item
        v-if="hasPermission('manage settings mobile app')"
        :to="{ name: 'setting general' }"
        icon="i-far-square-sliders"
      >
        Umum
      </sidebar-item>
      <sidebar-item
        v-if="hasPermission('manage settings mobile app')"
        :to="{ name: 'setting template share product' }"
        icon="i-far-share"
      >
        Share Produk
      </sidebar-item>
      <sidebar-item
        v-if="hasPermission('manage settings mobile app')"
        :to="{ name: 'setting splashscreen' }"
        icon="i-far-mobile"
      >
        Splash Screen
      </sidebar-item>
      <sidebar-item
        v-if="hasPermission('manage settings mobile app')"
        :to="{ name: 'setting notification sound' }"
        icon="i-far-music"
      >
        Suara Notifikasi
      </sidebar-item>
    </template>
  </div>
</template>
