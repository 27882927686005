import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { usePageStore } from '@/stores/page'
import LayoutDashboard from '@/views/layouts/layout_dashboard.vue'
import auth from './auth'
import banner from './banner'
import changelog from './changelog'
import company from './company'
import digitalProduct from './digital_product'
import digitalShopOrder from './digital_shop_order'
import employee from './employee'
import partner from './partner'
import partnerShopOrder from './partner_shop_order'
import partnerStockRequest from './partner_stock_request'
import paymentMethod from './payment_method'
import popupHome from './popup_home'
import product from './product'
import productBundle from './product_bundle'
import productCategory from './product_category'
import productReview from './product_review'
import promo from './promo'
import region from './region'
import settingAccount from './setting_account'
import settingShop from './setting_shop'
import shopOrder from './shop_order'
import shopOrderPayment from './shop_order_payment'
import topup from './topup'
import voucher from './voucher'

/**
 * Make sure all routes has meta with these attributes.
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string
  }
}

const routes: RouteRecordRaw[] = [
  useLayoutDashboard('', [
    {
      path: '',
      name: 'dashboard',
      component: () => import('@/views/dashboard/page_dashboard.vue'),
      meta: { title: 'Dashboard' },
    },
  ]),
  useLayoutDashboard('/digital-produk', digitalProduct),
  useLayoutDashboard('/produk', product),
  useLayoutDashboard('/perusahaan', company),
  useLayoutDashboard('/karyawan', employee),
  useLayoutDashboard('/kategori-produk', productCategory),
  useLayoutDashboard('/bundle-produk', productBundle),
  useLayoutDashboard('/pesanan', shopOrder),
  useLayoutDashboard('/pesanan-produk-digital', digitalShopOrder),
  useLayoutDashboard('/pesanan/konfirmasi-pembayaran', shopOrderPayment),
  useLayoutDashboard('/topup', topup),
  useLayoutDashboard('/wilayah', region),
  useLayoutDashboard('/riwayat-perubahan', changelog),
  useLayoutDashboard('/ulasan', productReview),
  useLayoutDashboard('/pengaturan', settingShop),
  useLayoutDashboard('/akun', settingAccount),
  useLayoutDashboard('/promo', promo),
  useLayoutDashboard('/voucher', voucher),
  useLayoutDashboard('/banner', banner),
  useLayoutDashboard('/popup-home', popupHome),
  useLayoutDashboard('/metode-pembayaran', paymentMethod),
  useLayoutDashboard('/mitra/penarikan-saldo-profit', [
    {
      path: '',
      name: 'partner withdraw all',
      component: () => import('@/views/partner/withdraw_balance/page-list.vue'),
      meta: { title: 'Daftar Penarikan Saldo Profit' },
    },
  ]),
  useLayoutDashboard('/mitra/pelanggan', [
    {
      path: '',
      name: 'partner customer all',
      component: () => import('@/views/partner/user/page_list.vue'),
      meta: { title: 'Pelanggan Semua Mitra' },
    },
  ]),
  useLayoutDashboard('/mitra/minta-stok', partnerStockRequest),
  useLayoutDashboard('/mitra/penjualan', partnerShopOrder),
  useLayoutDashboard('/mitra/produk', [
    {
      path: '',
      name: 'partner product list',
      component: () => import('@/views/partner/product_default/page_list.vue'),
      meta: { title: 'Produk Mitra' },
    },
  ]),
  useLayoutDashboard('/mitra', partner),
  useLayoutDashboard('/notifikasi', [
    {
      path: '',
      name: 'notification list',
      component: () => import('@/views/notification/page_list.vue'),
      meta: { title: 'Notifikasi' },
    },
  ]),
  useLayoutDashboard('/hak-akses', [
    {
      path: '',
      name: 'role list',
      component: () => import('@/views/permission/page_role_list.vue'),
      meta: { title: 'Pengaturan Hak Akses' },
    },
  ]),
  useLayoutDashboard('/daftar-admin', [
    {
      path: '',
      name: 'admin list',
      component: () => import('@/views/permission/page_admin_list.vue'),
      meta: { title: 'Daftar Admin' },
    },
  ]),
  auth,
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/errors/error_503.vue'),
    meta: { title: 'Maintenance' },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/error_404.vue'),
    meta: { title: '404' },
  },
]

function useLayoutDashboard(
  path: string,
  children: RouteRecordRaw[],
): RouteRecordRaw {
  return {
    path,
    children,
    component: LayoutDashboard,
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        // el: to.hash, // does not work well on changelog page
        // behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 250) // 50ms after route transition
    })
  },
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  page.resetErrorState()
  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  setTimeout(() => {
    page.setTitle(to.meta.title)
  }, 250) // 50ms after route transition

  next()
})

export default router
