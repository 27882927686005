import type { App } from 'vue'
import clickOutside from './clickOutside'
import focus from './focus'

export default {
  install(app: App) {
    app.directive('focus', focus)
    app.directive('click-outside', clickOutside)
  },
}
