<script setup lang="ts">
  import {
    Menu as AppMenu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/vue'
  import { computed } from 'vue'
  import { fmt } from '@/functions'
  import { replaceUrlNotification } from '@/models/notification'
  import { useNotificationStore } from '@/stores/notification'

  const notificationStore = useNotificationStore()
  const notifications = computed(() => notificationStore.data)
  const clickNotification = (id: string, close: () => void) => {
    notificationStore.readOne(id)
    close()
  }
</script>

<template>
  <div>
    <app-menu as="div" class="relative inline-block text-left">
      <menu-button class="relative">
        <div>
          <span class="i-far-bell"></span>
        </div>
        <span
          v-if="notificationStore.count"
          class="absolute -top-1 left-2 h-4 overflow-hidden rounded bg-red-500 px-1 text-3xs text-white"
        >
          {{ notificationStore.shortenedCount }}
        </span>
      </menu-button>

      <transition name="scale">
        <menu-items class="card w-96">
          <div
            v-if="notifications.length === 0"
            class="flex h-40 items-center justify-center text-black"
          >
            Tidak ada notifikasi
          </div>
          <menu-item
            v-for="notification in notifications"
            :key="notification.id"
            v-slot="{ close }"
          >
            <router-link
              class="menu-item"
              :class="{ unread: !notification.read_at }"
              :to="replaceUrlNotification(notification.url)"
              @click="clickNotification(notification.id, close)"
            >
              <div class="line-clamp-3 w-full text-sm">
                {{ notification.content }}
              </div>
              <div class="text-xs opacity-75">
                {{ fmt.dateRelative(notification.created_at) }}
              </div>
            </router-link>
          </menu-item>
          <hr />
          <menu-item v-slot="{ close }">
            <router-link
              :to="{ name: 'notification list' }"
              class="block w-full py-2 text-center font-medium hover:bg-primary-50"
              @click="close"
            >
              Lihat Lebih Banyak
            </router-link>
          </menu-item>
        </menu-items>
      </transition>
    </app-menu>
  </div>
</template>

<style lang="postcss" scoped>
  .card {
    @apply absolute right-0 mt-2 rounded focus:outline-none;
    @apply text-sm;
  }

  .menu-item {
    @apply block items-center break-words px-4 py-2 text-gray-900;
    /* @apply hover:text-white hover:bg-gradient-to-bl hover:from-primary-500 hover:to-primary-600; */
    @apply hover:bg-primary-50;

    &.unread {
      @apply bg-yellow-50 hover:bg-primary-50;
    }
  }
</style>
