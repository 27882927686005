import { watchDebounced } from '@vueuse/core'
import { computed, reactive, ref, watch } from 'vue'
import { fmt } from '@/functions'
import { useApi } from '@/functions/api'
import { useAuthStore } from '@/stores/auth'
import { useDashboardStore } from '@/stores/dashboard'
import { useNotificationStore } from '@/stores/notification'

export function useDashboard() {
  const api = useApi()

  const chartPeriod = ref('monthly')
  const loading = ref(false)
  const revenue = ref<Revenue[]>([])
  const countOrders = ref<CountOrderChart[]>([])
  const topCategories = ref<CategoryBestSeller>({ total: 0, data: [] })
  const topProducts = ref<ProductBestSeller[]>([])

  watch(chartPeriod, () => {
    revenue.value = []
    countOrders.value = []
    topCategories.value = { total: 0, data: [] }
    getStats()
  })

  const startedDate = computed(() => {
    const dt = new Date()
    if (chartPeriod.value === 'yearly') {
      // set date to 5 years ago at 1 jan
      dt.setFullYear(dt.getFullYear() - 5)
      dt.setMonth(0)
      dt.setDate(1)
    } else if (chartPeriod.value === 'daily') {
      // set date to 7 days ago
      dt.setDate(dt.getDate() - 7)
    } else {
      // set date to 1st date of 12 months ago
      dt.setMonth(dt.getMonth() - 11)
      dt.setDate(1)
    }
    return dt
  })
  const endedDate = computed(() => new Date())

  const subtitle = computed(() => {
    const formatDate = 'dd MMM yyyy'

    if (chartPeriod.value === 'yearly') {
      return (
        startedDate.value.getFullYear() + ' - ' + endedDate.value.getFullYear()
      )
    } else if (chartPeriod.value === 'daily') {
      return (
        fmt.date(startedDate.value, formatDate) +
        ' - ' +
        fmt.date(endedDate.value, formatDate)
      )
    }
    return (
      fmt.date(startedDate.value, formatDate).slice(-8) +
      ' - ' +
      fmt.date(endedDate.value, formatDate).slice(-8)
    )
  })

  const apiParams = computed(() => {
    let format = 'yyyy-MM'
    if (chartPeriod.value === 'daily') {
      format = 'yyyy-MM-dd'
    } else if (chartPeriod.value === 'yearly') {
      format = 'yyyy'
    }

    return {
      start: fmt.date(startedDate.value, format),
      end: fmt.date(endedDate.value, format),
      selected_type: chartPeriod.value,
    }
  })

  const getStats = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseDashboard>(
        'dashboard/stats',
        apiParams.value,
      )
      countOrders.value = response.count_orders_chart ?? []
      topCategories.value = response.category_best_seller ?? {
        total: 0,
        data: [],
      }
      revenue.value = response.revenue ?? []
      topProducts.value = response.product_best_seller ?? []
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    chartPeriod,
    subtitle,
    getStats,

    revenue,
    countOrders,
    topCategories,
    topProducts,
  }
}

interface User {
  id: number
  name: string
  email: string
}
interface ResponseInit {
  user: User | null
  permissions: string[] | null
  deviceKey: string
  loggingOut: boolean
  loading: boolean
  notification: {
    count: number
    data: Notification[]
  }
  orders_count: {
    cancel_request: number
    complained: number
    new: number
    waiting_payment: number
  }
  digital_shop_order_count: {
    pending: number
  }
  reviews_count: {
    need_moderation: number
  }
  topups_count: {
    admin_pending: number
  }
  partner_stock_request_count: {
    new: number
  }
  partner_balance_withdraw_count: {
    pending: number
  }
}
export function useDashboardInit() {
  const api = useApi()
  const notification = useNotificationStore()
  const dashboard = useDashboardStore()
  const auth = useAuthStore()

  const init = async () => {
    try {
      auth.setLoading()
      const response = await api.GET<ResponseInit>('dashboard/init')
      auth.setUser(response.user)
      auth.setPermissions(response.permissions)

      dashboard.setReviewsCount(response.reviews_count.need_moderation)
      dashboard.setTopupsCountPending(response.topups_count.admin_pending)
      dashboard.setDigitalShopOrdersPending(
        response.digital_shop_order_count.pending,
      )
      dashboard.setOrdersCountNew(response.orders_count.new)
      dashboard.setOrdersCountWaitingPayment(
        response.orders_count.waiting_payment,
      )
      dashboard.setOrdersCountComplained(response.orders_count.complained)
      dashboard.setOrdersCountCancelRequest(
        response.orders_count.cancel_request,
      )
      dashboard.setPartnerStockRequestCount(
        response.partner_stock_request_count.new,
      )
      dashboard.setPartnerBalanceWithdrawCount(
        response.partner_balance_withdraw_count.pending,
      )
      notification.setUnreadCount(response.notification.count)
      notification.setData(response.notification.data)
    } finally {
      auth.setLoading(false)
    }
  }

  return {
    init,
  }
}

export function useDashboardSearch() {
  const api = useApi()

  const loading = ref(false)
  const data = ref<{ id: GroupType; data: ResponseSearch[] }[]>([])
  const selected = ref<'all' | GroupType>('all')

  const groups = ref<GroupType[]>([
    'Pesanan',
    'Pesanan Digital',
    'produk',
    'Kategori Produk',
    'voucher',
    'promo',
    'wilayah',
    'Perusahaan',
    'Karyawan',
    'Spanduk',
  ])

  const filterCollection = computed(() => {
    return data.value.filter(
      (d) => d.id === selected.value || selected.value === 'all',
    )
  })

  const form = reactive({ search: '' })

  const getRouteDetail = (dataSearch: ResponseSearch) => {
    switch (dataSearch.group) {
      case 'Perusahaan':
        return {
          name: 'company statistic',
          params: { companyId: dataSearch.id },
        }
      case 'Karyawan':
        return { name: 'employee show', params: { id: dataSearch.id } }
      case 'Kategori Produk':
        return { name: 'product category edit', params: { id: dataSearch.id } }
      case 'Pesanan':
        return { name: 'shop order show', params: { id: dataSearch.id } }
      case 'Pesanan Digital':
        return {
          name: 'digital shop order detail',
          params: { id: dataSearch.id },
        }
      case 'produk':
        return { name: 'product edit', params: { id: dataSearch.id } }
      case 'Spanduk':
        return { name: 'banner edit', params: { id: dataSearch.id } }
      case 'voucher':
        return { name: 'voucher detail', params: { id: dataSearch.id } }
      case 'promo':
        return { name: 'promo detail', params: { id: dataSearch.id } }
      case 'wilayah':
        return { name: 'region list', query: { search: dataSearch.name } }
      default:
        return { name: '' }
    }
  }

  const getRoutePageList = (group: GroupType) => {
    switch (group) {
      case 'Perusahaan':
        return { name: 'company list', query: form }
      case 'Karyawan':
        return { name: 'employee list', query: form }
      case 'Kategori Produk':
        return { name: 'product category list' }
      case 'Pesanan':
        return { name: 'shop order list', query: form }
      case 'Pesanan Digital':
        return { name: 'digital shop order list', query: form }
      case 'produk':
        return { name: 'product list', query: form }
      case 'Spanduk':
        return { name: 'banner list', query: form }
      case 'voucher':
        return { name: 'voucher list', query: form }
      case 'promo':
        return { name: 'promo list', query: form }
      case 'wilayah':
        return { name: 'region list', query: form }
      default:
        return { name: '' }
    }
  }

  const getData = async () => {
    try {
      loading.value = true
      data.value = []
      const response = await api.GET<ResponseSearch[]>('dashboard/search', form)
      data.value = groups.value.map((group) => ({
        id: group,
        data: response.filter((d) => d.group === group),
      }))

      // total data desc
      data.value.sort((a, b) => b.data.length - a.data.length)
    } finally {
      loading.value = false
    }
  }

  watchDebounced(
    form,
    () => {
      if (form.search) {
        getData()
      }
    },
    { debounce: 500 },
  )

  return {
    loading,
    selected,
    groups,
    form,
    data,
    filterCollection,
    getRouteDetail,
    getRoutePageList,
    getData,
  }
}
