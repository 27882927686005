export default [
  {
    path: '',
    name: 'shop order list',
    meta: { title: 'Daftar Pesanan' },
    component: () => import('@/views/shop_order/page_list.vue'),
  },
  {
    path: ':id',
    name: 'shop order show',
    meta: { title: 'Detail Pesanan' },
    props: true,
    component: () => import('@/views/shop_order/page_show.vue'),
  },
]
