export default [
  {
    path: '',
    name: 'voucher list',
    component: () => import('@/views/voucher/page_list.vue'),
    meta: { title: 'Daftar Voucher' },
  },
  {
    path: 'tambah',
    name: 'voucher create',
    component: () => import('@/views/voucher/page_create.vue'),
    meta: { title: 'Tambah Voucher' },
  },
  {
    path: ':id/duplikat',
    name: 'voucher duplicate',
    component: () => import('@/views/voucher/page_create.vue'),
    meta: { title: 'Tambah Voucher' },
  },
  {
    path: ':id/edit',
    name: 'voucher edit',
    component: () => import('@/views/voucher/page_edit.vue'),
    meta: { title: 'Edit Voucher' },
  },
  {
    path: ':id',
    name: 'voucher detail',
    component: () => import('@/views/voucher/page_show.vue'),
    meta: { title: 'Voucher' },
  },
]
