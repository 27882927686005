<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps<{
    src?: string | null
    alt?: string
  }>()

  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>

<template>
  <div class="overflow-hidden">
    <div v-if="!src || error" class="h-full w-full bg-gray-200"></div>
    <img
      v-else
      class="h-full w-full"
      :src="src"
      :alt="alt"
      @error="error = true"
    />
  </div>
</template>
