<script setup lang="ts">
  import { type InputHTMLAttributes, computed } from 'vue'

  const props = defineProps<{
    modelValue: InputHTMLAttributes['value']
    value?: InputHTMLAttributes['value']
    disabled?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: InputHTMLAttributes['value']): void
  }>()

  const model = computed({
    set: (value) => {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <label
    :class="[
      'flex items-baseline text-sm',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    ]"
  >
    <input
      v-if="value === undefined"
      v-model="model"
      v-bind="$attrs"
      :disabled="disabled"
      class="peer cursor-pointer"
      type="radio"
    />
    <input
      v-else
      v-model="model"
      v-bind="$attrs"
      :disabled="disabled"
      class="peer cursor-pointer"
      type="radio"
      :value="value"
    />
    <span
      class="ml-3 peer-hover:text-black peer-disabled:opacity-40 peer-hover:peer-disabled:text-current"
    >
      <slot></slot>
    </span>
  </label>
</template>
