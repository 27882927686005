export default [
  {
    path: '',
    name: 'popup home list',
    component: () => import('@/views/popup_home/page_list.vue'),
    meta: { title: 'Daftar Popup Home' },
  },
  {
    path: 'tambah',
    name: 'popup home create',
    component: () => import('@/views/popup_home/page_form.vue'),
    meta: { title: 'Tambah Popup Home' },
  },
  {
    path: ':id',
    name: 'popup home edit',
    component: () => import('@/views/popup_home/page_form.vue'),
    props: true,
    meta: { title: 'Edit Popup Home' },
  },
]
