import { defineStore } from 'pinia'

interface State {
  count: number
  data: Notification[]
}

export const useNotificationStore = defineStore('Notification', {
  state: (): State => ({
    count: 0,
    data: [],
  }),
  getters: {
    shortenedCount(state) {
      if (this.count < 100) {
        return state.count.toString()
      }
      return '99+'
    },
  },
  actions: {
    setUnreadCount(count: number) {
      this.count = count
    },
    decrementUnreadCount() {
      this.count--
    },
    setData(data: Notification[]) {
      this.data = data
    },
    findById(id: string) {
      return this.data.find((notification) => notification.id === id)
    },
    markAsRead(id: string) {
      const notification = this.findById(id)
      if (notification) {
        notification.read_at = new Date().toJSON()
      }
    },
    readOne(id: string) {
      this.decrementUnreadCount()
      this.markAsRead(id)
    },
    readAll() {
      this.setUnreadCount(0)
      this.data.forEach((notification) => {
        notification.read_at = new Date().toJSON()
      })
    },
  },
})
