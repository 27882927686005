<script setup lang="ts">
  import AppToast from '@/components/app_toast.vue'
  import { useApi } from '@/functions/api'
  import { useAuthStore } from '@/stores/auth'
  import { usePageStore } from '@/stores/page'
  // import PwaUpdatePrompt from './components/the_pwa_update_prompt.vue'
  import ErrorForbidden from '@/views/errors/error_403.vue'
  import ErrorNotFound from '@/views/errors/error_404.vue'
  import { useDashboardInit } from './models/dashboard'

  const auth = useAuthStore()
  const page = usePageStore()
  const api = useApi()

  api.GET('sanctum/csrf-cookie')

  // show page or redirect to login if not authenticated
  const { init } = useDashboardInit()
  init()
</script>

<template>
  <router-view v-if="!auth.loading" v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <error-not-found v-if="page.showError404"></error-not-found>
      <error-forbidden v-else-if="page.showError403"></error-forbidden>
      <component :is="Component" v-else></component>
    </transition>
  </router-view>
  <app-toast></app-toast>
  <!-- <pwa-update-prompt /> -->
</template>
