export default [
  {
    path: '',
    name: 'promo list',
    component: () => import('@/views/promo/page_list.vue'),
    meta: { title: 'Daftar Promo' },
  },
  {
    path: 'tambah',
    name: 'promo create',
    component: () => import('@/views/promo/page_form.vue'),
    meta: { title: 'Tambah Promo' },
  },
  {
    path: ':id',
    name: 'promo detail',
    props: true,
    component: () => import('@/views/promo/page_show.vue'),
    meta: { title: 'Promo' },
  },
  {
    path: ':id/edit',
    name: 'promo edit',
    props: true,
    component: () => import('@/views/promo/page_form.vue'),
    meta: { title: 'Ubah Promo' },
  },
  {
    path: ':id/duplikat',
    name: 'promo duplicate',
    props: true,
    component: () => import('@/views/promo/page_form.vue'),
    meta: { title: 'Duplikat Promo' },
  },
]
