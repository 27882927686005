export default [
  {
    path: '',
    name: 'product category list',
    component: () => import('@/views/product_category/page_list.vue'),
    meta: { title: 'Daftar Kategori Produk' },
  },
  {
    path: 'tambah',
    name: 'product category create',
    component: () => import('@/views/product_category/page_form.vue'),
    meta: { title: 'Tambah Kategori Produk' },
  },
  {
    path: ':id/edit',
    name: 'product category edit',
    component: () => import('@/views/product_category/page_form.vue'),
    meta: { title: 'Edit Kategori Produk' },
  },
]
