import { defineStore } from 'pinia'
import { useApi } from '@/functions/api'

interface User {
  id: number
  name: string
  email: string
}
interface State {
  user: User | null
  permissions: string[]
  deviceKey: string
  loggingOut: boolean
  loading: boolean
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    user: null,
    permissions: [],
    deviceKey: '',
    loggingOut: false,
    loading: false,
  }),
  getters: {
    hasPermission: (state) => (permission: string) => {
      return state.permissions.includes(permission)
    },
  },
  actions: {
    setDeviceKey(key: string) {
      this.deviceKey = key
      const api = useApi()
      api.POST('notification/firebase', { device_key: key })
    },
    setLoading(isLoading = true) {
      this.loading = isLoading
    },
    logout() {
      this.loggingOut = true
      const api = useApi()
      api.POST('logout', { device_key: this.deviceKey })
      this.router.replace({ name: 'login' })
      this.$reset()
    },
    setUser(user: User | null) {
      this.user = user
    },
    resetUser() {
      this.user = null
    },
    setPermissions(permissions: string[] | null) {
      this.permissions = permissions ?? []
    },
  },
})

/*
Permissions :
'manage admins'
'manage companies'
'manage bills'
'manage products'
'manage stock products'
'manage orders'
'manage payment methods'
'confirm order payments'
'manage shop banners'
'manage own deliveries'
'manage vouchers'
'manage promos'
'manage reviews'
'manage home popups'
'manage mscash topups'
'manage mscash'
'manage regions'
'manage settings employee point'
'manage settings default additional fee'
'manage settings shop'
'manage settings template share product'
'manage settings reviews'
'manage settings mobile app'
'see chart revenue'
'see chart count orders'
'see chart companies'
*/
