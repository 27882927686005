<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    modelValue: string
    placeholder?: string
    loading?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
    (e: 'submit', value: string): void
  }>()

  const text = computed({
    set: (value) => {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div class="relative">
    <app-input
      v-model="text"
      type="search"
      :placeholder="placeholder ?? 'Cari...'"
      @keyup.enter="emit('submit', text)"
    ></app-input>
    <div
      class="absolute inset-y-0 flex items-center px-4 text-sm text-gray-500"
    >
      <span v-if="loading" class="i-far-circle-notch animate-spin"></span>
      <span v-else class="i-far-magnifying-glass"></span>
    </div>
    <div
      class="absolute inset-y-0 right-2 flex items-center text-xs text-gray-500"
    >
      <button
        v-if="text"
        type="button"
        class="z-10 flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-200"
        @click="text = ''"
      >
        <span class="i-fas-xmark"></span>
      </button>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() {
    input {
      @apply pl-11 pr-8;
    }
  }
</style>
