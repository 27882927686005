<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import TheNavbar from '@/components/navbar/the_navbar.vue'
  import TheSidebar from '@/components/sidebar/the_sidebar.vue'
  import TheFooter from '@/components/the_footer.vue'
  // import startFCM from '@/services/firebase'
  import { useAuthStore } from '@/stores/auth'

  const auth = useAuthStore()

  const sidebarShrank = ref(false)
  const sidebarShow = ref(false)
  const toggleSidebarSize = () => {
    sidebarShrank.value = !sidebarShrank.value
  }
  const toggleSidebarShow = () => {
    sidebarShow.value = !sidebarShow.value
  }

  onMounted(() => {
    // startFCM()
  })
</script>

<template>
  <div
    v-show="auth.user"
    :class="{ 'sidebar-mini': sidebarShrank, 'sidebar-show': sidebarShow }"
  >
    <div class="screen">
      <div class="container">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" class="min-h-[70vh]"></component>
          </transition>
        </router-view>
        <the-footer></the-footer>
      </div>
    </div>

    <the-navbar @toggle-sidebar="toggleSidebarShow"></the-navbar>

    <the-sidebar
      @toggle-sidebar-show="toggleSidebarShow"
      @toggle-sidebar-size="toggleSidebarSize"
    ></the-sidebar>
  </div>
</template>
