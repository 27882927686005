<script setup lang="ts">
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any[]
      modelValue: number[]
      active?: boolean
    }>(),
    { active: true },
  )

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number[]): void
  }>()

  const selected = computed({
    set: (value) => {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
  const selectedAll = computed({
    set: (value) => {
      if (value) {
        selected.value = props.data.map((data) => data.id)
      } else {
        selected.value = []
      }
    },
    get: () =>
      selected.value.length > 0 &&
      props.data.every((data) => selected.value.includes(data.id)),
  })
  const selectAllIndeterminate = computed(() => {
    const selectedSome = selected.value.length > 0
    const notAllSelected = selected.value.length < props.data.length
    return selectedSome && notAllSelected
  })
</script>

<template>
  <app-table :checkable="active">
    <thead>
      <th v-if="active" class="w-px text-center">
        <app-checkbox
          v-model="selectedAll"
          :indeterminate="selectAllIndeterminate"
        ></app-checkbox>
      </th>
      <slot name="thead"></slot>
    </thead>
    <slot>
      <tbody>
        <tr v-for="(item, index) in data" :key="item.id">
          <td v-if="active" class="w-px">
            <app-checkbox v-model="selected" :value="item.id"></app-checkbox>
          </td>
          <slot name="tbody" :item="item" :index="index"></slot>
        </tr>
      </tbody>
    </slot>
  </app-table>
</template>
