import { defineStore } from 'pinia'

const APP_TITLE = 'MS Shop Dashboard'

export const usePageStore = defineStore('Page', {
  state: () => ({
    title: '',
    showError403: false,
    showError404: false,
  }),
  actions: {
    setTitle(title: string) {
      this.title = title
      document.title = `${title} | ${APP_TITLE}`
    },
    resetErrorState() {
      this.showError403 = false
      this.showError404 = false
    },
    showNotFoundError() {
      this.showError404 = true
    },
    showForbiddenError() {
      this.showError403 = true
    },
  },
})
