import * as Sentry from '@sentry/vue'
import {type App } from 'vue'
import {type Router } from 'vue-router'

export default {
  install(app: App<Element>, router: Router) {
    // dont run in development
    if (import.meta.env.DEV) {
      return
    }

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['dashboard.msshop.co.id', /^\//],
        }),
      ],
      release: 'msshop-dashboard@' + __APP_VERSION__,
      ignoreErrors: [
        // stackoverflow says this can be safely ignored: https://stackoverflow.com/a/50387233/3671954
        'ResizeObserver loop limit exceeded',
        // axios server common https statuses
        'Request failed with status code 401',
        'Request failed with status code 403',
        'Request failed with status code 404',
        'Request failed with status code 419',
        'Request failed with status code 422',
        'Request failed with status code 429',
        'Request failed with status code 500',
        'Request failed with status code 503',
        'Request aborted',
        '/timeout of 30000/',
        '/privateSpecialRepair/', // weird vivo browser error
        'Failed to fetch dynamically imported module',
        // don't know what caused this error and why is this reported to sentry
        'Network Error',
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.001,
    })
  },
}
