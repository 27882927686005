export default [
  {
    path: '',
    name: 'banner list',
    component: () => import('@/views/banner/page_list.vue'),
    meta: { title: 'Daftar Banner' },
  },
  {
    path: 'tambah',
    name: 'banner create',
    component: () => import('@/views/banner/page_form.vue'),
    meta: { title: 'Tambah Banner' },
  },
  {
    path: ':id',
    name: 'banner edit',
    component: () => import('@/views/banner/page_form.vue'),
    props: true,
    meta: { title: 'Edit Banner' },
  },
]
