<script setup lang="ts"></script>

<template>
  <section class="xl:flex xl:space-x-4">
    <div class="mb-4 w-full max-w-60">
      <h3 class="mb-1 font-semibold">
        <slot name="title"></slot>
      </h3>
      <h4 class="text-xs text-gray-400">
        <slot name="subtitle"></slot>
      </h4>
      <slot name="after-subtitle"></slot>
    </div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </section>
</template>
