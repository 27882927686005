import type { App } from 'vue'
import AppAnimatedDot from './app_animated_dot.vue'
import AppButton from './app_button.vue'
import AppCard from './app_card.vue'
import AppCheckbox from './app_checkbox.vue'
import AppDatepicker from './app_datepicker.vue'
import AppErrorMsg from './app_error_msg.vue'
import AppFilterBar from './app_filter_bar.vue'
import AppFilterBarItem from './app_filter_bar_item.vue'
import AppForm from './app_form.vue'
import AppFormSection from './app_form_section.vue'
import AppImage from './app_image.vue'
import AppInput from './app_input.vue'
import AppModal from './app_modal.vue'
import AppNumeric from './app_numeric.vue'
import AppPagination from './app_pagination.vue'
import AppRadio from './app_radio.vue'
import AppSearch from './app_search.vue'
import AppSearchPage from './app_search_page.vue'
import AppSelect from './app_select.vue'
import AppSubmit from './app_submit.vue'
import AppSwitch from './app_switch.vue'
import AppTabStatus from './app_tab_status.vue'
import AppTabStatusItem from './app_tab_status_item.vue'
import AppTable from './app_table.vue'
import AppTableCheckable from './app_table_checkable.vue'
import AppTableColMoney from './app_table_col_money.vue'
import AppTableInfo from './app_table_info.vue'
import AppTableInfoRow from './app_table_info_row.vue'

export default {
  install(app: App) {
    app.component('AppAnimatedDot', AppAnimatedDot)
    app.component('AppButton', AppButton)
    app.component('AppCard', AppCard)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppDatepicker', AppDatepicker)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppFilterBar', AppFilterBar)
    app.component('AppFilterBarItem', AppFilterBarItem)
    app.component('AppForm', AppForm)
    app.component('AppFormSection', AppFormSection)
    app.component('AppImage', AppImage)
    app.component('AppInput', AppInput)
    app.component('AppModal', AppModal)
    app.component('AppNumeric', AppNumeric)
    app.component('AppPagination', AppPagination)
    app.component('AppRadio', AppRadio)
    app.component('AppSearch', AppSearch)
    app.component('AppSearchPage', AppSearchPage)
    app.component('AppSelect', AppSelect)
    app.component('AppSubmit', AppSubmit)
    app.component('AppSwitch', AppSwitch)
    app.component('AppTable', AppTable)
    app.component('AppTableCheckable', AppTableCheckable)
    app.component('AppTableColMoney', AppTableColMoney)
    app.component('AppTableInfo', AppTableInfo)
    app.component('AppTableInfoRow', AppTableInfoRow)
    app.component('AppTabStatus', AppTabStatus)
    app.component('AppTabStatusItem', AppTabStatusItem)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppAnimatedDot: typeof AppAnimatedDot
    AppButton: typeof AppButton
    AppCard: typeof AppCard
    AppCheckbox: typeof AppCheckbox
    AppDatepicker: typeof AppDatepicker
    AppErrorMsg: typeof AppErrorMsg
    AppFilterBar: typeof AppFilterBar
    AppFilterBarItem: typeof AppFilterBarItem
    AppForm: typeof AppForm
    AppFormSection: typeof AppFormSection
    AppImage: typeof AppImage
    AppInput: typeof AppInput
    AppModal: typeof AppModal
    AppNumeric: typeof AppNumeric
    AppPagination: typeof AppPagination
    AppRadio: typeof AppRadio
    AppSearch: typeof AppSearch
    AppSearchPage: typeof AppSearchPage
    AppSelect: typeof AppSelect
    AppSubmit: typeof AppSubmit
    AppSwitch: typeof AppSwitch
    AppTable: typeof AppTable
    AppTableCheckable: typeof AppTableCheckable
    AppTableColMoney: typeof AppTableColMoney
    AppTableInfo: typeof AppTableInfo
    AppTableInfoRow: typeof AppTableInfoRow
    AppTabStatus: typeof AppTabStatus
    AppTabStatusItem: typeof AppTabStatusItem
  }
}
