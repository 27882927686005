export default [
  {
    path: '',
    name: 'partner stock request list',
    component: () => import('@/views/partner/stock_request/page_list.vue'),
    meta: { title: 'Daftar Permintaan Stock Mitra' },
  },
  {
    path: ':id',
    name: 'partner stock request detail',
    props: true,
    component: () => import('@/views/partner/stock_request/page_show.vue'),
    meta: { title: 'Detail Permintaan Stock Mitra' },
  },
]
