import { useRouter } from 'vue-router'

export function useErrorPage() {
  const router = useRouter()

  const back = () => {
    if (window.history.state.position > 1) {
      router.go(-1)
    } else {
      router.push({ name: 'home' })
    }
  }

  const getImage = (filename: string) => {
    return import.meta.env.VITE_BASE_API + '/img/backgrounds/' + filename
  }

  return {
    back,
    getImage,
  }
}
