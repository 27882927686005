<script setup lang="ts">
  import { usePageStore } from '@/stores/page'
  import DropdownNotification from './navbar_dropdown_notification.vue'
  import DropdownUser from './navbar_dropdown_user.vue'
  import NavbarSearch from './navbar_search.vue'

  const emit = defineEmits<{
    (e: 'toggleSidebar'): void
  }>()

  const page = usePageStore()
</script>

<template>
  <nav class="navbar">
    <div class="container-fluid h-full py-0">
      <div
        class="relative flex h-full items-center justify-between gap-x-4 border-b"
      >
        <div class="hidden lg:block">
          <h1 class="font-semibold sm:text-lg">
            {{ page.title }}
          </h1>
        </div>

        <navbar-search></navbar-search>

        <div class="lg:hidden">
          <app-button @click="emit('toggleSidebar')">
            <span class="i-fas-bars"></span>
          </app-button>
        </div>

        <div class="hidden space-x-8 lg:flex">
          <dropdown-notification></dropdown-notification>
          <dropdown-user></dropdown-user>
        </div>
      </div>
    </div>
  </nav>
</template>
