<script setup lang="ts">
  import SidebarItems from './sidebar_items.vue'

  const emit = defineEmits<{
    (e: 'toggleSidebarShow'): void
    (e: 'toggleSidebarSize'): void
  }>()

  const logo = '/img/icons/icon-72x72.png'
</script>

<template>
  <div>
    <div class="sidebar-dimmer" @click="emit('toggleSidebarShow')"></div>
    <nav class="sidebar">
      <div
        class="sidebar-header flex items-center justify-between space-x-4 overflow-hidden"
      >
        <div class="flex-shrink-0">
          <img :src="logo" alt="logo" class="sidebar-company-icon" />
        </div>
        <div
          class="flex-1 flex-shrink-0 whitespace-nowrap text-xl font-semibold"
        >
          MS Shop
        </div>
        <div class="hidden flex-shrink-0 pr-1 text-right lg:block">
          <app-button class="text-2xl" @click="emit('toggleSidebarSize')">
            <span class="icon-lock">
              <span class="i-far-lock"></span>
            </span>
            <span class="icon-unlock">
              <span class="i-far-lock-open"></span>
            </span>
          </app-button>
        </div>
        <div class="lg:hidden">
          <app-button class="text-2xl" @click="emit('toggleSidebarShow')">
            <span class="i-fas-xmark"></span>
          </app-button>
        </div>
      </div>

      <hr />

      <sidebar-items></sidebar-items>
    </nav>
  </div>
</template>

<style lang="postcss"></style>
