export default [
  {
    path: 'splashscreen',
    name: 'setting splashscreen',
    component: () => import('@/views/setting/page_setting_splashscreen.vue'),
    meta: { title: 'Pengaturan Splash Screen' },
  },
  {
    path: 'suara-notifikasi',
    name: 'setting notification sound',
    component: () =>
      import('@/views/setting/page_setting_notification_sound.vue'),
    meta: { title: 'Pengaturan Suara Notifikasi' },
  },
  {
    path: 'template-share-produk',
    name: 'setting template share product',
    component: () =>
      import('@/views/setting/page_setting_template_share_product.vue'),
    meta: { title: 'Pengaturan Share Produk' },
  },
  {
    path: 'umum',
    name: 'setting general',
    component: () => import('@/views/setting/page_setting_general.vue'),
    meta: { title: 'Pengaturan Umum' },
  },
]
