<script setup lang="ts">
  import { Menu as AppMenu, MenuItem, MenuItems } from '@headlessui/vue'
  import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useDashboardSearch } from '@/models/dashboard'

  const {
    loading,
    selected,
    groups,
    form,
    filterCollection,
    data: allData,
    getRouteDetail,
    getRoutePageList,
  } = useDashboardSearch()

  watch(filterCollection, () => {
    const el = document.querySelector('#globalSearchDropdown')
    if (el) el.scrollTop = 0 // scroll dropdown to top
  })

  const inputElement = (callback: (el: HTMLInputElement) => void) => {
    const el = document.querySelector('#globalSearch input[type=search]')
    if (el instanceof HTMLInputElement) {
      callback(el)
    }
  }

  const isOpenSearch = ref(false)
  const closeNavbarSearch = () => {
    isOpenSearch.value = false
  }

  const openSearch = () => {
    form.search = ''
    selected.value = 'all'
    isOpenSearch.value = true
    inputElement((el) => {
      nextTick(() => {
        el.focus()
      })
    })
  }

  // const isFocused = ref(false)
  const onInputFocused = () => {
    // isFocused.value = true
    openSearch()
  }
  onMounted(() => {
    inputElement((el) => {
      el.addEventListener('focus', onInputFocused)
    })
  })
  onBeforeUnmount(() => {
    inputElement((el) => {
      el.removeEventListener('focus', onInputFocused)
    })
  })
  const clickSearchResult = (close: () => void) => {
    form.search = ''
    close()
  }
</script>

<template>
  <!-- <div v-if="isOpenSearch" class="fixed inset-0 z-40 bg-black bg-opacity-80"></div> -->
  <div
    class="z-50 flex-1"
    :class="{ 'hidden lg:block': !isOpenSearch, block: isOpenSearch }"
  >
    <div v-click-outside="closeNavbarSearch" class="mx-auto lg:max-w-96">
      <!-- <div v-click-outside="closeNavbarSearch" class="w-full"> -->
      <app-menu as="div" class="relative text-left">
        <div class="bg-white">
          <app-search id="globalSearch" v-model="form.search"></app-search>
        </div>
        <transition name="scale">
          <menu-items v-if="form.search" class="card" static>
            <!-- <div class="p-4"> -->
            <div class="px-4 pt-4">
              <div
                class="flex gap-2 overflow-auto border-b pb-4 scrollbar-none lg:flex-wrap"
              >
                <button
                  class="badge-navbar active"
                  :class="{
                    'border-none bg-primary-500 text-white': selected === 'all',
                  }"
                  @click="selected = 'all'"
                >
                  All
                </button>
                <button
                  v-for="group in groups"
                  :key="group"
                  class="badge-navbar"
                  :class="{
                    'border-none bg-primary-500 text-white': selected === group,
                  }"
                  @click="selected = group"
                >
                  {{ group }}
                </button>
              </div>
            </div>
            <div
              v-if="loading || allData.length === 0"
              class="flex h-64 items-center justify-center p-4 text-gray-400"
            >
              <template v-if="loading">Memuat Data...</template>
              <template v-else>Data tidak ditemukan</template>
            </div>
            <div
              v-else
              id="globalSearchDropdown"
              class="max-h-72 overflow-auto pr-4"
            >
              <div
                v-for="group in filterCollection"
                :key="group.id"
                class="mb-4 border-b py-2"
              >
                <div class="mb-2 px-4 text-lg font-medium capitalize">
                  {{ group.id }}
                </div>

                <menu-item
                  v-for="data in group.data"
                  :key="data.id"
                  v-slot="{ close }"
                >
                  <div class="menu-item">
                    <router-link
                      :to="getRouteDetail(data)"
                      class="w-full"
                      @click="clickSearchResult(close)"
                    >
                      {{ data.name }}
                    </router-link>
                  </div>
                </menu-item>

                <div
                  v-if="group.data.length === 0"
                  class="flex h-20 items-center justify-center text-sm text-gray-400"
                >
                  Tidak ada data
                </div>
                <menu-item v-else>
                  <router-link
                    :to="getRoutePageList(group.id)"
                    class="mt-2 flex w-full justify-center text-center text-xs text-primary-500"
                    @click="closeNavbarSearch"
                  >
                    Tampilkan Semua
                  </router-link>
                </menu-item>
              </div>
            </div>
            <!-- </div> -->
          </menu-items>
        </transition>
      </app-menu>
      <!-- </div> -->
    </div>
  </div>

  <button
    class="form-input flex flex-1 items-center space-x-2 bg-white text-gray-400 lg:hidden"
    :class="{ hidden: isOpenSearch }"
    @click="openSearch"
  >
    <div class="text-sm">
      <span class="i-far-magnifying-glass"></span>
    </div>
    <div>Cari...</div>
  </button>
</template>

<style lang="postcss" scoped>
  .badge-navbar {
    @apply flex-shrink-0 rounded border px-1.5 py-1 text-sm capitalize;
  }
  .card {
    @apply absolute inset-x-0 mt-2 p-1 focus:outline-none;
    @apply text-sm;
  }

  .menu-item {
    @apply flex w-full items-center truncate px-4 py-2 text-gray-900;
    /* px-4 rounded-md */
    @apply break-words hover:bg-primary-50;
  }
</style>
