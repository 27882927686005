export default [
  {
    path: '',
    name: 'product list',
    component: () => import('@/views/product/page_list.vue'),
    meta: { title: 'Daftar Produk' },
  },
  {
    path: ':id',
    name: 'product show',
    props: true,
    component: () => import('@/views/product/page_show.vue'),
    meta: { title: 'Produk' },
  },
  {
    path: 'tambah',
    name: 'product create',
    component: () => import('@/views/product/page_form.vue'),
    meta: { title: 'Tambah Produk' },
  },
  {
    path: ':id/edit',
    name: 'product edit',
    props: true,
    component: () => import('@/views/product/page_form.vue'),
    meta: { title: 'Edit Produk' },
  },
  {
    path: ':id/duplikat',
    props: true,
    name: 'product duplicate',
    component: () => import('@/views/product/page_form.vue'),
    meta: { title: 'Duplikat Produk' },
  },
]
