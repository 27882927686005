<script setup lang="ts">
  withDefaults(
    defineProps<{
      submitting: boolean
      disabled?: boolean
      withCancel?: boolean
    }>(),
    {
      disabled: false,
      withCancel: true,
    },
  )

  const emit = defineEmits<{
    (e: 'submit'): void
    (e: 'cancel'): void
  }>()
</script>

<template>
  <form @submit.prevent="emit('submit')">
    <slot></slot>

    <slot name="action">
      <div
        class="flex items-center justify-center space-x-4 xl:justify-start xl:pl-64"
      >
        <app-submit
          class="btn-primary w-32"
          :disabled="disabled"
          :loading="submitting"
        >
          Simpan
        </app-submit>
        <app-button
          v-if="withCancel"
          class="btn-info w-32"
          :disabled="submitting"
          @click="emit('cancel')"
        >
          Batal
        </app-button>
      </div>
    </slot>
  </form>
</template>

<style lang="postcss" scoped>
  :deep() {
    section {
      @apply mb-8 border-b pb-8;
    }
  }
</style>
