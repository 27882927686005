export default [
  {
    path: '',
    name: 'company list',
    meta: { title: 'Daftar Perusahaan' },
    component: () => import('@/views/company/page_list.vue'),
  },
  {
    path: ':companyId',
    component: () => import('@/views/company/page_show.vue'),
    props: true,
    children: [
      {
        path: 'statistik',
        name: 'company statistic',
        component: () => import('@/views/company/company_stats.vue'),
        props: true,
        meta: { title: 'Statistik' },
      },
      {
        path: 'karyawan',
        name: 'company employee list',
        component: () => import('@/views/company/employee/page_list.vue'),
        props: true,
        meta: { title: 'Daftar Karyawan' },
      },
      {
        path: 'karyawan/import',
        name: 'company employee import',
        component: () =>
          import('@/views/company/employee/import/page_import.vue'),
        props: true,
        meta: { title: 'Import Karyawan' },
      },
      {
        path: 'jabatan',
        name: 'company position list',
        component: () => import('@/views/company/position/page_list.vue'),
        props: true,
        meta: { title: 'Daftar Jabatan' },
      },
      {
        path: 'alamat',
        name: 'company address list',
        component: () => import('@/views/company/address/page_address.vue'),
        props: true,
        meta: { title: 'Daftar Alamat' },
      },
    ],
  },

  // {
  //   path: ':companyId/alamat/tambah',
  //   name: 'company address create',
  //   component: () => import('@/views/company/address/page_form.vue'),
  //   props: true,
  //   meta: { title: 'Tambah Alamat Perusahaan' },
  // },
  // {
  //   path: ':companyId/alamat/edit/:addressId',
  //   name: 'company address edit',
  //   component: () => import('@/views/company/address/page_form.vue'),
  //   props: true,
  //   meta: { title: 'Edit Alamat Perusahaan' },
  // },
]
