import { computed, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { translateENShopOrderStatus } from '@/functions'
import { useApi } from '@/functions/api'
import { useDashboardStore } from '@/stores/dashboard'

export const SHOP_ORDER_STATUS_WAITING_PAYMENT = 'waiting_payment'
export const SHOP_ORDER_STATUS_NEW = 'new'
export const SHOP_ORDER_STATUS_PROCESSING = 'processing'
export const SHOP_ORDER_STATUS_DELIVERING = 'delivering'
export const SHOP_ORDER_STATUS_RECEIVED = 'received'
export const SHOP_ORDER_STATUS_CANCEL_REQUESTED = 'cancel_requested'
export const SHOP_ORDER_STATUS_CANCELED = 'canceled'
export const SHOP_ORDER_STATUS_COMPLAINED = 'complained'
export const SHOP_ORDER_STATUS_COMPLAINT_RESPONDED = 'complaint_responded'
export const SHOP_ORDER_STATUS_COMPLETED = 'completed'

export function useShopOrderGetList() {
  const api = useApi()
  const route = useRoute()
  const routeName = ref(route.name)
  const dashboard = useDashboardStore()

  const loading = ref(false)
  const pagination = ref<Pagination>()
  const shopOrders = ref<ShopOrder[]>([])

  const countCancelRequested = ref(0)
  const countCanceled = ref(0)
  const countComplained = ref(0)
  const countComplaintResponded = ref(0)
  const countCompleted = ref(0)
  const countDelivering = ref(0)
  const countNew = ref(0)
  const countProcessing = ref(0)
  const countReceived = ref(0)

  const getPaymentMethod = (data?: string) => {
    switch (data) {
      case 'Kredit':
        return 'credits'
      case 'Poin':
        return 'points'
      case 'MS Cash':
        return 'mscash'
      default:
        return data
    }
  }

  const apiParams = computed(() => {
    return {
      search: route.query.search as string | undefined,
      status: translateENShopOrderStatus(
        route.query.status as string | undefined,
      ),
      payment_method: getPaymentMethod(
        route.query.pembayaran as string | undefined,
      ),
      page: route.query.page as string | undefined,
    }
  })

  const getData = async () => {
    loading.value = true
    try {
      const response = await api.GET<ResponseShopOrder>(
        'shop-order/admin',
        apiParams.value,
      )
      shopOrders.value = response.data
      countCancelRequested.value = response.count_cancel_requested
      countCanceled.value = response.count_canceled
      countComplained.value = response.count_complained
      countComplaintResponded.value = response.count_complaint_responded
      countCompleted.value = response.count_completed
      countDelivering.value = response.count_delivering
      countNew.value = response.count_new
      countProcessing.value = response.count_processing
      countReceived.value = response.count_received
      pagination.value = response.meta
      dashboard.setOrdersCountNew(countNew.value)
    } finally {
      loading.value = false
    }
  }

  watch(apiParams, () => {
    if (routeName.value === route.name) {
      getData()
    }
  })

  return {
    loading,
    pagination,
    shopOrders,
    countCancelRequested,
    countCanceled,
    countComplained,
    countComplaintResponded,
    countCompleted,
    countDelivering,
    countNew,
    countProcessing,
    countReceived,

    getData,
  }
}

export function useShopOrderGetDetail() {
  const api = useApi()

  const loading = ref(false)
  const shopOrder = ref<ShopOrderDetail>()
  const pagination = ref<DetailPagination>()

  const orderStatusUpdated = (data: ShopOrderDetail) => {
    if (shopOrder.value) {
      shopOrder.value = {
        ...shopOrder.value,
        ...data,
        user: shopOrder.value.user,
      }
    }
  }

  const calculateDiscount = (item: ShopOrderItem) => {
    return item.price - item.price_after_discount
  }

  const getData = async (id: number) => {
    loading.value = true
    try {
      const response = await api.GET<ApiResource<ShopOrderDetail>>(
        `shop-order/admin/${id}`,
      )
      shopOrder.value = response.data
      pagination.value = response.meta
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    pagination,
    shopOrder,

    calculateDiscount,
    orderStatusUpdated,
    getData,
  }
}

export function useShopOrderDownload() {
  const api = useApi()
  const loading = ref(false)

  const getFile = async (form: object, callback: () => void) => {
    loading.value = true
    try {
      await api.DOWNLOAD('shop-order/excel/download', undefined, form, 'post')
      callback()
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    getFile,
  }
}

export function useShopOrderUpdateProcessing() {
  const api = useApi()
  const loading = ref(false)

  const submit = async (
    id: number,
    callback: (data: ShopOrderDetail) => void,
  ) => {
    loading.value = true
    try {
      const response = await api.POST<ShopOrderDetail>(
        `shop-order/processing/${id}`,
      )
      callback(response)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    submit,
  }
}

export function useShopOrderUpdateDelivery() {
  const api = useApi()
  const submitting = ref(false)
  const errors = ref<FormError>({})

  const couriers = ref<User[]>([])

  const submit = async (
    id: number,
    form: object,
    callback: (data: ShopOrderDetail) => void,
  ) => {
    submitting.value = true
    try {
      const response = await api.POST<ShopOrderDetail>(
        `shop-order/delivering/${id}`,
        form,
      )
      callback(response)
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  return {
    submitting,
    errors,
    couriers,
    submit,
  }
}

export function useShopOrderDestroyDelivery() {
  const api = useApi()
  const loading = ref(false)

  const submit = async (
    id: number,
    callback: (data: ShopOrderDetail) => void,
  ) => {
    loading.value = true
    try {
      const response = await api.DELETE<ShopOrderDetail>(
        `shop-order/delivering/${id}`,
      )
      callback(response)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    submit,
  }
}

export function useShopOrderCancelRequestRespond() {
  const api = useApi()
  const submitting = ref(false)
  const errors = ref<FormError>({})

  const submit = async (
    id: number,
    form: object,
    callbackSuccess: (data: ShopOrderDetail) => void,
    callbackNeedPasswordConfirmation: () => void,
  ) => {
    submitting.value = true
    try {
      const response = await api.PUT<ShopOrderDetail>(
        `shop-order/cancel-request/${id}`,
        form,
      )
      callbackSuccess(response)
    } catch (error) {
      if (api.isNeedPasswordConfirmation(error)) {
        callbackNeedPasswordConfirmation()
        return
      }
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  return {
    submitting,
    errors,
    submit,
  }
}

export function useShopOrderComplaintRespond() {
  const api = useApi()
  const submitting = ref(false)
  const errors = ref<FormError>({})

  const submit = async (
    id: number,
    form: object,
    callbackSuccess: (data: ShopOrderDetail) => void,
    callbackNeedPasswordConfirmation: () => void,
  ) => {
    submitting.value = true
    try {
      const response = await api.PUT<ShopOrderDetail>(
        `shop-order/complaint/${id}`,
        form,
      )
      callbackSuccess(response)
    } catch (error) {
      if (api.isNeedPasswordConfirmation(error)) {
        callbackNeedPasswordConfirmation()
        return
      }
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  return {
    submitting,
    errors,
    submit,
  }
}

export function useShopOrderReceive() {
  const api = useApi()
  const submitting = ref(false)
  const errors = ref<FormError>({})
  const form = reactive<{ recipient_name: string; shop_orders: number[] }>({
    recipient_name: '',
    shop_orders: [],
  })

  const setReceive = async (
    callbackSuccess: (data: ResponseShopOrderReceive[]) => void,
  ) => {
    submitting.value = true
    try {
      const response = await api.POST<ResponseShopOrderReceive[]>(
        'shop-order/received',
        form,
      )
      callbackSuccess(response)
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  return {
    submitting,
    errors,
    form,
    setReceive,
  }
}

export function useShopOrderCancelReceive() {
  const api = useApi()
  const loading = ref(false)
  const errors = ref<FormError>({})

  const submit = async (
    id: number,
    callback: (data: ShopOrderDetail) => void,
  ) => {
    loading.value = true
    try {
      const response = await api.DELETE<ShopOrderDetail>(
        `shop-order/received/${id}`,
      )
      callback(response)
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    submit,
  }
}

export function useShopOrderCancelReceiveBulk() {
  const api = useApi()
  const submitting = ref(false)
  const form = reactive<{ shop_orders: number[] }>({ shop_orders: [] })
  const errors = ref<FormError>({})

  const submit = async (callback: () => void) => {
    submitting.value = true
    try {
      await api.POST('shop-order/received/undo', form)
      callback()
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  return {
    submitting,
    form,
    submit,
  }
}
