<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    disabled?: boolean
    modelValue: string
  }>()
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const model = computed({
    set: (val) => {
      emit('update:modelValue', val ?? '')
    },
    get: () => props.modelValue,
  })

  const formattedDate = computed(() => {
    if (props.modelValue) {
      return fmt.date(props.modelValue, 'd MMM yyyy')
    }

    return ''
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)

  const openDatePicker = () => {
    const el = document.getElementById(inputId.value)
    if (el && el instanceof HTMLInputElement) {
      el.showPicker()
    }
  }
</script>

<template>
  <app-input :id="inputId" :label="label" :error="error">
    <div class="flex flex-wrap gap-4">
      <div class="relative flex-1">
        <input
          :id="inputId"
          v-model="model"
          type="date"
          class="-z-1 absolute inset-0 opacity-0"
          :disabled="disabled"
          :required="required"
          tabindex="9999"
        />
        <app-input
          :model-value="formattedDate"
          readonly
          class="min-w-32"
          :placeholder="placeholder"
          @focus="openDatePicker()"
          @click="openDatePicker()"
        ></app-input>
        <div
          class="leading-0 pointer-events-none absolute inset-y-0 right-2 flex items-center text-sm"
        >
          <span class="i-far-calendar"></span>
        </div>
      </div>
      <slot></slot>
    </div>
  </app-input>
</template>
