export default [
  {
    path: '',
    name: 'product bundle list',
    component: () => import('@/views/product_bundle/page_list.vue'),
    meta: { title: 'Daftar Paket Bundle' },
  },
  {
    path: 'tambah',
    name: 'product bundle create',
    component: () => import('@/views/product_bundle/page_form.vue'),
    meta: { title: 'Tambah Paket Bundle' },
  },
  {
    path: ':id/duplikat',
    name: 'product bundle duplicate',
    props: true,
    component: () => import('@/views/product_bundle/page_form.vue'),
    meta: { title: 'Tambah Paket Bundle' },
  },
  {
    path: ':id/edit',
    name: 'product bundle edit',
    props: true,
    component: () => import('@/views/product_bundle/page_form.vue'),
    meta: { title: 'Edit Paket Bundle' },
  },
]
