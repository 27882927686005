<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { fmt } from '@/functions'

  const route = useRoute()
  const router = useRouter()

  const props = defineProps<{
    modelValue?: Pagination
  }>()

  const page = computed({
    set: (value: number) => {
      changePage(value)
    },
    get: () => {
      return currentPage.value ? currentPage.value : 1
    },
  })

  const currentPage = computed(() => props.modelValue?.current_page ?? 0)
  const lastPage = computed(() => props.modelValue?.last_page ?? 0)
  const prevLink = computed(() => {
    if (currentPage.value <= 1) {
      return
    }
    const prevPage = currentPage.value === 2 ? undefined : currentPage.value - 1
    return { ...route, query: { ...route.query, page: prevPage } }
  })
  const nextLink = computed(() => {
    if (currentPage.value >= lastPage.value) {
      return
    }
    return { ...route, query: { ...route.query, page: page.value + 1 } }
  })

  watch(currentPage, () => {
    if (!currentPage.value) {
      return
    }
    page.value = currentPage.value
  })

  const changePage = (value: number) => {
    const newPage = value <= 1 ? undefined : value
    router.push({ ...route, query: { ...route.query, page: newPage } })
  }

  const pages = computed(() => {
    if (lastPage.value) {
      return Array.from({ length: lastPage.value }, (_, i) => i + 1)
    }
    return [1]
  })
</script>

<template>
  <nav
    v-if="modelValue && lastPage && currentPage <= lastPage && lastPage > 1"
    role="navigation"
    aria-label="Pagination Navigation"
  >
    <div class="my-4 flex items-center justify-between">
      <div class="hidden text-xs md:block">
        Menampilkan
        <span class="font-semibold">
          {{ fmt.number(modelValue.from) }}-{{ fmt.number(modelValue.to) }}
        </span>
        dari
        <span class="font-semibold">{{ fmt.number(modelValue.total) }}</span>
      </div>
      <div class="flex items-center text-sm">
        <router-link
          v-if="prevLink"
          :to="prevLink"
          class="mr-4 text-primary-500"
        >
          Kembali
        </router-link>
        <div>
          <app-select v-model="page">
            <option v-for="p in pages" :key="p" :value="p">
              {{ fmt.number(p) }}
            </option>
          </app-select>
        </div>
        <div class="ml-2 mr-4">/</div>
        <div>
          {{ fmt.number(lastPage) }}
        </div>
        <div>
          <router-link
            v-if="nextLink"
            :to="nextLink"
            class="ml-4 text-primary-500"
          >
            Lanjut
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
